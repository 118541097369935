<template>
  <div>
    <b-container class="p-5">
      <h3>添加员工</h3>
      <b-card class="shadow w-75 p-3 mt-5">
        <b-form v-if="show">
          <b-form-group
              id="input-group-1"
              label="员工名"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="form.name"
                type="text"
                placeholder="请输入姓名..."
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="组织" label-for="input-2">
            <el-select class="w-100" v-model="selected" placeholder="请选择" >
              <el-option
                  v-for="item in organizations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </b-form-group>

          <b-form-group
              id="input-group-3"
              label="状态"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="form.status"
                type="text"
                placeholder="Enter Status"
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="example-datepicker">有效期</label>
            <b-form-datepicker id="example-datepicker" v-model="form.validity" class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-button class="w-25 mt-3" variant="primary" @click="addStaff">添加</b-button>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {createStaff, showMyOrganizations} from "@/api";

export default {
  name: "CreateStaff",
  data() {
    return {
      organizations: [],
      selected: '',
      form: {
        name: '',
        organizationId: '',
        status: '',
        validity: ''
      },
      foods: [{text: 'Select One', value: null}, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },
  mounted() {
    this.getMyOrganizations()
  },
  methods: {
    getMyOrganizations() {
      showMyOrganizations(1, 50).then(res => {
        if (res.data.status) {
          this.organizations = res.data.data.content
        }
      })
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    addStaff() {
      createStaff(this.form.name, this.selected, this.form.status, this.form.validity + ' 00:00:00').then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
